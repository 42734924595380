import React from 'react'
import TopHeader from './TopHeader'
import MainHeader from './MainHeader'
import MainFooter from './MainFooter'
import SeoData from './SeoData';

export default function StaticDynamicWebsiteDevelopment() {
  return (
    <>
        <SeoData/>
        <TopHeader/>
      <div className="container-fluid position-relative p-0">
        <MainHeader/>
        <div className="container-fluid bg-primary py-5 bg-header" style={{marginBottom: "90px"}}>
            <div className="row py-5">
                <div className="col-12 pt-lg-5 mt-lg-5 text-center">
                    <h1 className="display-4 text-white animated zoomIn">Website Development</h1>
                    <a href="#" className="h5 text-white">Services</a>
                    <i className="far fa-circle text-white px-2"></i>
                    <a href="#" className="h5 text-white">Website Development</a>
                </div>
            </div>
        </div>
    </div>
    {/* <!-- Navbar & Carousel End --> */}


    {/* <!-- Full Screen Search Start --> */}
    <div className="modal fade" id="searchModal" tabIndex="-1">
        <div className="modal-dialog modal-fullscreen">
            <div className="modal-content" style={{background: "rgba(9, 30, 62, .7)"}}>
                <div className="modal-header border-0">
                    <button type="button" className="btn bg-white btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body d-flex align-items-center justify-content-center">
                    <div className="input-group" style={{maxWidth: "600px"}}>
                        <input type="text" className="form-control bg-transparent border-primary p-3" placeholder="Type search keyword"/>
                        <button className="btn btn-primary px-4"><i className="bi bi-search"></i></button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    {/* <!-- Full Screen Search End --> */}


    {/* <!-- About Start --> */}
    <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
        <div className="container py-5">
            <div className="row g-5">
                <div className="col-lg-7">
                    <div className="section-title position-relative pb-3 mb-5">
                        <h5 className="fw-bold text-primary text-uppercase">Website Design & Development</h5>
                        <h1 className="mb-0">Static / Dynamic</h1>
                    </div>
                    <p className="mb-4" align="justify">Welcome to Ayodhya Technologies, where we specialize in delivering innovative website development solutions tailored to meet your unique business needs. Our expert team of developers, designers, and strategists work collaboratively to create visually stunning, user-friendly websites that not only captivate your audience but also drive conversions. We understand that a website is often the first point of contact with your customers, so we prioritize responsive design, seamless navigation, and optimized performance.</p>

                    <p className="mb-4" align="justify">From initial concept and design to development and ongoing support, we utilize the latest technologies and best practices to ensure your website stands out in today’s competitive digital landscape. Whether you need a simple landing page, a robust e-commerce platform, or a complex web application, we are dedicated to delivering high-quality solutions that align with your goals. Our commitment to excellence extends beyond launch; we offer ongoing maintenance and optimization services to keep your website up-to-date and performing at its best.</p>

                    <p className="mb-4" align="justify">At Ayodhya Technologies, we believe in building strong partnerships with our clients. We take the time to understand your business, your audience, and your objectives, allowing us to create a tailored web solution that truly reflects your brand. Let us help you elevate your online presence and achieve your digital ambitions. Contact us today to discuss your website development project!</p>

                </div>
                <div className="col-lg-5" style={{minHeight: "500px"}}>
                    <div className="position-relative h-100">
                        <img className="position-absolute w-100 h-100 rounded wow zoomIn" alt='' data-wow-delay="0.9s" src="assets/img/web_static_dynamic.jpg" style={{objectFit: "cover"}}/>
                    </div>
                </div>
            </div>
        </div>
    </div>
    {/* <!-- Service End --> */}

        <MainFooter/>

    </>
  )
}
