import React from 'react'
import { NavLink,useLocation,useNavigate  } from 'react-router-dom'


export default function MainHeader() {

    const navigate=useNavigate();
   
    // console.log();
    const location = useLocation();


  return (
    <>


    


        <nav className="navbar navbar-expand-lg navbar-dark px-5 py-3 py-lg-0">
            <NavLink to={`${process.env.PUBLIC_URL}/`} className="navbar-brand p-0">
                 <img src="assets/img/ayodhya_logo.png" />
            </NavLink>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
                <span className="fa fa-bars"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarCollapse">
                <div className="navbar-nav ms-auto py-0">

                    {/* <a href="index.html" className="nav-item nav-link active">Home</a> */}
                    <NavLink className="nav-item nav-link" to={`${process.env.PUBLIC_URL}/`} > Home</NavLink>

                    <div className="nav-item dropdown">
                        <a href="#" className="nav-link dropdown-toggle" data-bs-toggle="dropdown">About</a>
                        <div className="dropdown-menu m-0">

                            {/* <a href="about_us.html" className="dropdown-item">About us</a> */}
                            <NavLink className="dropdown-item " to={`${process.env.PUBLIC_URL}/about`} > About</NavLink>
                            <NavLink className="dropdown-item " to={`${process.env.PUBLIC_URL}/why-ayodhya-technologies`} > Why Ayodhya Technologies</NavLink>
                            <NavLink className="dropdown-item " to={`${process.env.PUBLIC_URL}/vision-and-mission`} > Our Vision and Mission</NavLink>


                            {/* <a href="about_us.html" className="dropdown-item">Why Ayodhya Technologies</a>
                            <a href="about_us.html" className="dropdown-item">Our Vision and Mission</a> */}
                        </div>
                    </div>

                    <div className="nav-item dropdown">
                        <a href="#" className="nav-link dropdown-toggle" data-bs-toggle="dropdown">Services</a>
                        <div className="dropdown-menu m-0">
                            <NavLink className="dropdown-item" to={`${process.env.PUBLIC_URL}/static-dynamic-website-development`} > Static/Dynamic Website Development</NavLink>
                            <NavLink className="dropdown-item" to={`${process.env.PUBLIC_URL}/custom-web-application`} > Custom Web application Development</NavLink>
                            <NavLink className="dropdown-item" to={`${process.env.PUBLIC_URL}/mobile-application`} > Mobile Application (Android/IOS App) Development</NavLink>
                            <NavLink className="dropdown-item" to={`${process.env.PUBLIC_URL}/api-integration`} > API Integrations</NavLink>
                            <NavLink className="dropdown-item" to={`${process.env.PUBLIC_URL}/graphics-designing`} > Graphics Designing</NavLink>
                            <NavLink className="dropdown-item" to={`${process.env.PUBLIC_URL}/search-engine-optimization`} > Search Engine Optimization</NavLink>
                            <NavLink className="dropdown-item" to={`${process.env.PUBLIC_URL}/digital-marketing`} > Digital Marketing</NavLink>

                            {/* <a href="website_development.html" className="dropdown-item">Static/Dynamic Website Development</a>
                            <a href="custom_web_development.html" className="dropdown-item">Custom Web application Development</a>
                            <a href="mobile_app_development.html" className="dropdown-item">Mobile Application (Android/IOS App) Development</a>
                            <a href="api_integration.html" className="dropdown-item">API Integrations</a>
                            <a href="graphics_designing.html" className="dropdown-item">Graphics Designing</a>
                            <a href="seo.html" className="dropdown-item">Search Engine Optimization</a>
                            <a href="digital_marketing.html" className="dropdown-item">Digital Marketing</a> */}
                        </div>
                    </div>

                    <div className="nav-item dropdown">
                        <a href="#" className="nav-link dropdown-toggle" data-bs-toggle="dropdown">Products</a>
                        <div className="dropdown-menu m-0">

                            <NavLink className="dropdown-item" to={`${process.env.PUBLIC_URL}/educational-erp`} > Educational ERP</NavLink>
                            <NavLink className="dropdown-item" to={`${process.env.PUBLIC_URL}/hospital-erp-solution`} > Hospital ERP Solution</NavLink>
                            <NavLink className="dropdown-item" to={`${process.env.PUBLIC_URL}/crm-solution`} > CRM Solution</NavLink>
                            <NavLink className="dropdown-item" to={`${process.env.PUBLIC_URL}/e-commerce-application`} > E-Commerce Application</NavLink>
                            <NavLink className="dropdown-item" to={`${process.env.PUBLIC_URL}/inventory-management`} > Inventory Management</NavLink>
                            <NavLink className="dropdown-item" to={`${process.env.PUBLIC_URL}/gym-management`} > Gym Management</NavLink>
                            <NavLink className="dropdown-item" to={`${process.env.PUBLIC_URL}/restaurant-management`} > Restaurant Management</NavLink>
                            <NavLink className="dropdown-item" to={`${process.env.PUBLIC_URL}/employee-management`} > Employee Management</NavLink>

                            {/* <a href="under_construction.html" className="dropdown-item">Educational ERP</a>
                            <a href="under_construction.html" className="dropdown-item">Hospital ERP Solution</a>
                            <a href="under_construction.html" className="dropdown-item">CRM Solution</a>
                            <a href="under_construction.html" className="dropdown-item">E-Commerce Application</a>
                            <a href="under_construction.html" className="dropdown-item">Inventory Management</a>
                            <a href="under_construction.html" className="dropdown-item">Gym Management</a>
                            <a href="under_construction.html" className="dropdown-item">Restaurant Management</a>
                            <a href="under_construction.html" className="dropdown-item">Employee Management</a> */}
                        </div>
                    </div>

                    {/* <NavLink className="nav-item nav-link" to={`${process.env.PUBLIC_URL}/team-members`} > Team Members</NavLink> */}
                    {/* <NavLink className="nav-item nav-link" to={`${process.env.PUBLIC_URL}/our-blog`} > Our Blog</NavLink> */}
                    <NavLink className="nav-item nav-link" to={`${process.env.PUBLIC_URL}/contact-us`} > Contact Us</NavLink>

                    {/* <a href="our_team.html" className="nav-item nav-link">Team Members</a>
                    <a href="our_blog.html" className="nav-item nav-link">Our Blog</a>
                    <a href="contact_us.html" className="nav-item nav-link">Contact Us</a> */}

                    
                </div>
                {/* <butaton type="button" className="btn text-primary ms-3" data-bs-toggle="modal" data-bs-target="#searchModal"><i className="fa fa-search"></i></butaton> */}
                <NavLink className="btn btn-primary py-2 px-4 ms-3" to={`${process.env.PUBLIC_URL}/pricing-plan`} > Pricing Plan</NavLink>
                {/* <a href="https://ayodhyatechnologies.com/" className="btn btn-primary py-2 px-4 ms-3">Pricing Plan</a> */}
            </div>
        </nav>




        

    </>
  )
}
