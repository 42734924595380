import React from 'react'

export default function TopHeader() {
  return (
    <>
        {/* <div id="spinner" className="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center">
            <div className="spinner"></div>
        </div> */}


        <div className="container-fluid bg-dark px-5 d-none d-lg-block">
            <div className="row gx-0">
                <div className="col-lg-8 text-center text-lg-start mb-2 mb-lg-0">
                    <div className="d-inline-flex align-items-center" style={{height: "45px"}}>
                        <small className="me-3 text-light"><i className="fa fa-map-marker-alt me-2"></i>City Center Mall, 2nd Floor-5, Takiya Para, Durg</small>
                        <small className="me-3 text-light"><i className="fa fa-phone-alt me-2"></i>+91 7987274435</small>
                        <small className="text-light"><i className="fa fa-envelope-open me-2"></i>info@ayodhyatechnologies.com</small>
                    </div>
                </div>
                <div className="col-lg-4 text-center text-lg-end">
                    <div className="d-inline-flex align-items-center" style={{height: "45px"}}>
                        {/* <a className="btn btn-sm btn-outline-light btn-sm-square rounded-circle me-2" target="_blank" href="#"><i className="fab fa-twitter fw-normal"></i></a>
                        <a className="btn btn-sm btn-outline-light btn-sm-square rounded-circle me-2" target="_blank" href="#"><i className="fab fa-facebook-f fw-normal"></i></a> */}
                        <a className="btn btn-sm btn-outline-light btn-sm-square rounded-circle me-2" target="_blank" href="https://www.linkedin.com/in/ayodhya-technologies-software-company-000b82331"><i className="fab fa-linkedin-in fw-normal"></i></a>
                        <a className="btn btn-sm btn-outline-light btn-sm-square rounded-circle me-2" target="_blank" href="https://www.instagram.com/ayodhya_technologies/"><i className="fab fa-instagram fw-normal"></i></a>
                        <a className="btn btn-sm btn-outline-light btn-sm-square rounded-circle" target="_blank" href="https://www.youtube.com/@AyodhyaTechnologies"><i className="fab fa-youtube fw-normal"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}
