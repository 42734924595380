import React from 'react'
import TopHeader from './TopHeader'
import MainHeader from './MainHeader'
import MainFooter from './MainFooter'
import SeoData from './SeoData';

export default function SearchEngineOptimization() {
  return (
    <>
        <SeoData/>
        <TopHeader/>
      <div className="container-fluid position-relative p-0">
        <MainHeader/>
        <div className="container-fluid bg-primary py-5 bg-header" style={{marginBottom: "90px"}}>
            <div className="row py-5">
                <div className="col-12 pt-lg-5 mt-lg-5 text-center">
                    <h1 className="display-4 text-white animated zoomIn">Search Engine Optimization</h1>
                    <a href="#" className="h5 text-white">Services</a>
                    <i className="far fa-circle text-white px-2"></i>
                    <a href="#" className="h5 text-white">Search Engine Optimization</a>
                </div>
            </div>
        </div>
    </div>
    {/* <!-- Navbar & Carousel End --> */}


    {/* <!-- Full Screen Search Start --> */}
    <div className="modal fade" id="searchModal" tabIndex="-1">
        <div className="modal-dialog modal-fullscreen">
            <div className="modal-content" style={{background: "rgba(9, 30, 62, .7)"}}>
                <div className="modal-header border-0">
                    <button type="button" className="btn bg-white btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body d-flex align-items-center justify-content-center">
                    <div className="input-group" style={{maxWidth: "600px"}}>
                        <input type="text" className="form-control bg-transparent border-primary p-3" placeholder="Type search keyword"/>
                        <button className="btn btn-primary px-4"><i className="bi bi-search"></i></button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    {/* <!-- Full Screen Search End --> */}


    {/* <!-- About Start --> */}
    <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
        <div className="container py-5">
            <div className="row g-5">
                <div className="col-lg-7">
                    <div className="section-title position-relative pb-3 mb-5">
                        <h5 className="fw-bold text-primary text-uppercase">Search Engine Optimization</h5>
                        <h1 className="mb-0">Onpage / Offpage </h1>
                    </div>
                    <p className="mb-4" align="justify">At Ayodhya Technologies, we understand that having a great website is only half the battle; visibility is key to your online success. Our Search Engine Optimization (SEO) services are designed to enhance your website's visibility on search engines, driving organic traffic and increasing your brand's reach.</p>

                    <p className="mb-4" align="justify">Our experienced SEO team employs a comprehensive approach that includes keyword research, on-page optimization, technical SEO, and high-quality link building. We analyze your business goals and target audience to develop a tailored SEO strategy that aligns with your unique needs. By optimizing your website’s content, structure, and performance, we help improve your search engine rankings and ensure your site is easily discoverable by potential customers.</p>

                    <p className="mb-4" align="justify">At Ayodhya Technologies, we stay up-to-date with the latest industry trends and algorithm changes, ensuring that our strategies are effective and sustainable. Our ongoing monitoring and reporting provide you with insights into your SEO performance, allowing us to make data-driven adjustments for continuous improvement.</p>

                    <p className="mb-4" align="justify">Let us help you elevate your online presence and drive more traffic to your website with our expert SEO services. Contact Ayodhya Technologies today to discuss how we can help your business grow in the digital landscape!</p>


                </div>
                <div className="col-lg-5" style={{minHeight: "500px"}}>
                    <div className="position-relative h-100">
                        <img className="position-absolute w-100 h-100 rounded wow zoomIn" data-wow-delay="0.9s" src="assets/img/seo.jpg" style={{objectFit: "cover"}}/>
                    </div>
                </div>
            </div>
        </div>
    </div>
    {/* <!-- Service End --> */}

        <MainFooter/>

    </>
  )
}
