import React from 'react'
import TopHeader from './TopHeader'
import MainHeader from './MainHeader'
import MainFooter from './MainFooter'
import SeoData from './SeoData';

export default function DigitalMarketing() {
  return (
    <>
        <SeoData/>
        <TopHeader/>
      <div className="container-fluid position-relative p-0">
        <MainHeader/>
        <div className="container-fluid bg-primary py-5 bg-header" style={{marginBottom: "90px"}}>
            <div className="row py-5">
                <div className="col-12 pt-lg-5 mt-lg-5 text-center">
                    <h1 className="display-4 text-white animated zoomIn">Digital Marketing</h1>
                    <a href="#" className="h5 text-white">Services</a>
                    <i className="far fa-circle text-white px-2"></i>
                    <a href="#" className="h5 text-white">Digital Marketing</a>
                </div>
            </div>
        </div>
    </div>
    {/* <!-- Navbar & Carousel End --> */}


    {/* <!-- Full Screen Search Start --> */}
    <div className="modal fade" id="searchModal" tabIndex="-1">
        <div className="modal-dialog modal-fullscreen">
            <div className="modal-content" style={{background: "rgba(9, 30, 62, .7)"}}>
                <div className="modal-header border-0">
                    <button type="button" className="btn bg-white btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body d-flex align-items-center justify-content-center">
                    <div className="input-group" style={{maxWidth: "600px"}}>
                        <input type="text" className="form-control bg-transparent border-primary p-3" placeholder="Type search keyword"/>
                        <button className="btn btn-primary px-4"><i className="bi bi-search"></i></button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    {/* <!-- Full Screen Search End --> */}


    {/* <!-- About Start --> */}
    <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
        <div className="container py-5">
            <div className="row g-5">
                <div className="col-lg-7">
                    <div className="section-title position-relative pb-3 mb-5">
                        <h5 className="fw-bold text-primary text-uppercase">Digital Marketing</h5>
                        <h1 className="mb-0">Online Marketing </h1>
                    </div>
                    <p className="mb-4" align="justify">At Ayodhya Technologies, we recognize that effective digital marketing is essential for driving growth and reaching your target audience in today's competitive landscape. Our comprehensive digital marketing services are designed to enhance your online presence, engage customers, and generate measurable results.</p>

                    <p className="mb-4" align="justify">We offer a wide range of digital marketing solutions, including social media marketing, content marketing, pay-per-click (PPC) advertising, email marketing, and search engine optimization (SEO). Our experienced team works collaboratively with you to develop a customized digital marketing strategy that aligns with your business objectives and resonates with your audience.</p>

                    <p className="mb-4" align="justify">With a focus on data-driven insights, we analyze your market and target demographic to create compelling campaigns that deliver results. Our approach combines creativity with analytics, ensuring that every campaign is optimized for maximum impact. We continuously monitor performance and adjust strategies as needed to keep your brand at the forefront of digital trends.</p>

                    <p className="mb-4" align="justify">At Ayodhya Technologies, we are committed to helping you achieve your digital marketing goals. Let us elevate your brand and drive meaningful engagement with our tailored digital marketing solutions. Contact us today to learn how we can help you succeed in the digital landscape!</p>


                </div>
                <div className="col-lg-5" style={{minHeight: "500px"}}>
                    <div className="position-relative h-100">
                        <img className="position-absolute w-100 h-100 rounded wow zoomIn" data-wow-delay="0.9s" src="assets/img/digital_marketing.jpg" style={{objectFit: "cover"}}/>
                    </div>
                </div>
            </div>
        </div>
    </div>
    {/* <!-- Service End --> */}

        <MainFooter/>

    </>
  )
}
