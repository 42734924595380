import React from 'react'
import TopHeader from './TopHeader'
import MainHeader from './MainHeader'
import MainFooter from './MainFooter'
import SeoData from './SeoData';

export default function ApiIntegration() {
  return (
    <>
        <SeoData/>
        <TopHeader/>
      <div className="container-fluid position-relative p-0">
        <MainHeader/>
        <div className="container-fluid bg-primary py-5 bg-header" style={{marginBottom: "90px"}}>
            <div className="row py-5">
                <div className="col-12 pt-lg-5 mt-lg-5 text-center">
                    <h1 className="display-4 text-white animated zoomIn">API Integration</h1>
                    <a href="#" className="h5 text-white">Services</a>
                    <i className="far fa-circle text-white px-2"></i>
                    <a href="#" className="h5 text-white">API Integration</a>
                </div>
            </div>
        </div>
    </div>
    {/* <!-- Navbar & Carousel End --> */}


    {/* <!-- Full Screen Search Start --> */}
    <div className="modal fade" id="searchModal" tabIndex="-1">
        <div className="modal-dialog modal-fullscreen">
            <div className="modal-content" style={{background: "rgba(9, 30, 62, .7)"}}>
                <div className="modal-header border-0">
                    <button type="button" className="btn bg-white btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body d-flex align-items-center justify-content-center">
                    <div className="input-group" style={{maxWidth: "600px"}}>
                        <input type="text" className="form-control bg-transparent border-primary p-3" placeholder="Type search keyword"/>
                        <button className="btn btn-primary px-4"><i className="bi bi-search"></i></button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    {/* <!-- Full Screen Search End --> */}


    {/* <!-- About Start --> */}
    <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
        <div className="container py-5">
            <div className="row g-5">
            <div className="col-lg-7">
                    <div className="section-title position-relative pb-3 mb-5">
                        <h5 className="fw-bold text-primary text-uppercase">API Integration</h5>
                        <h1 className="mb-0">Different Technologies</h1>
                    </div>
                    <p className="mb-4" align="justify">At Ayodhya Technologies, we understand the critical role API integration plays in enhancing your business operations and ensuring seamless connectivity between systems. Our expert team specializes in developing robust API solutions that enable your applications to communicate effectively, share data, and automate workflows, ultimately driving efficiency and innovation.</p>

                    <p className="mb-4" align="justify">We offer comprehensive API integration services, including designing, developing, and implementing APIs tailored to your specific needs. Whether you need to connect third-party services, integrate legacy systems, or streamline internal processes, we leverage the latest technologies to create secure, scalable, and high-performing APIs that enhance your application's functionality.</p>

                    <p className="mb-4" align="justify">Our approach is collaborative and client-focused. We work closely with you to understand your requirements and ensure that our solutions align with your business goals. From initial consultation and planning to testing and deployment, we prioritize transparency and communication throughout the process, ensuring a smooth integration experience.</p>

                    <p className="mb-4" align="justify">At Ayodhya Technologies, we are committed to helping you unlock the full potential of your software ecosystem through effective API integration. Let us help you enhance your application's capabilities, improve user experiences, and drive business growth. Contact us today to learn more about our API integration services and how we can support your digital transformation journey!</p>


                </div>
                <div className="col-lg-5" style={{minHeight: "500px"}}>
                    <div className="position-relative h-100">
                        <img className="position-absolute w-100 h-100 rounded wow zoomIn" data-wow-delay="0.9s" src="assets/img/api_integraion.jpg" style={{objectFit: "cover"}}/>
                    </div>
                </div>
            </div>
        </div>
    </div>
    {/* <!-- Service End --> */}

        <MainFooter/>

    </>
  )
}
