import React from 'react'
import TopHeader from './TopHeader'
import MainHeader from './MainHeader'
import MainFooter from './MainFooter'
import SeoData from './SeoData';

export default function GraphicsDesigning() {
  return (
    <>
        <SeoData/>
        <TopHeader/>
      <div className="container-fluid position-relative p-0">
        <MainHeader/>
        <div className="container-fluid bg-primary py-5 bg-header" style={{marginBottom: "90px"}}>
            <div className="row py-5">
                <div className="col-12 pt-lg-5 mt-lg-5 text-center">
                    <h1 className="display-4 text-white animated zoomIn">Graphics Designing</h1>
                    <a href="#" className="h5 text-white">Services</a>
                    <i className="far fa-circle text-white px-2"></i>
                    <a href="#" className="h5 text-white">Graphics Designing</a>
                </div>
            </div>
        </div>
    </div>
    {/* <!-- Navbar & Carousel End --> */}


    {/* <!-- Full Screen Search Start --> */}
    <div className="modal fade" id="searchModal" tabIndex="-1">
        <div className="modal-dialog modal-fullscreen">
            <div className="modal-content" style={{background: "rgba(9, 30, 62, .7)"}}>
                <div className="modal-header border-0">
                    <button type="button" className="btn bg-white btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body d-flex align-items-center justify-content-center">
                    <div className="input-group" style={{maxWidth: "600px"}}>
                        <input type="text" className="form-control bg-transparent border-primary p-3" placeholder="Type search keyword"/>
                        <button className="btn btn-primary px-4"><i className="bi bi-search"></i></button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    {/* <!-- Full Screen Search End --> */}


    {/* <!-- About Start --> */}
    <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
        <div className="container py-5">
            <div className="row g-5">
                <div className="col-lg-7">
                    <div className="section-title position-relative pb-3 mb-5">
                        <h5 className="fw-bold text-primary text-uppercase">Graphics Designing</h5>
                        <h1 className="mb-0">Image / Video Editing </h1>
                    </div>
                    <p className="mb-4" align="justify">At Ayodhya Technologies, we believe that exceptional graphic design is the cornerstone of effective communication and branding. Our talented team of graphic designers is dedicated to creating visually compelling designs that capture your brand's essence and resonate with your audience. Whether you need stunning logos, eye-catching marketing materials, or engaging digital assets, we tailor our designs to meet your specific needs.</p>

                    <p className="mb-4" align="justify">We understand that great design goes beyond aesthetics; it plays a crucial role in conveying your message and enhancing user experience. Our collaborative approach ensures that we deeply understand your vision, target audience, and objectives, allowing us to create designs that not only look great but also drive results.</p>

                    <p className="mb-4" align="justify">From initial concept and brainstorming to final delivery, we prioritize creativity and attention to detail. Our services include branding, print design, digital graphics, infographics, and much more. We utilize the latest design tools and trends to deliver high-quality visuals that set your brand apart in a competitive marketplace.</p>

                    <p className="mb-4" align="justify">At Ayodhya Technologies, we are committed to helping you communicate your message effectively and make a lasting impression. Let us elevate your brand with our expert graphic design services. Contact us today to discuss how we can bring your vision to life!</p>


                </div>
                <div className="col-lg-5" style={{minHeight: "500px"}}>
                    <div className="position-relative h-100">
                        <img className="position-absolute w-100 h-100 rounded wow zoomIn" data-wow-delay="0.9s" src="assets/img/graphics_designing.jpg" style={{objectFit: "cover"}}/>
                    </div>
                </div>
            </div>
        </div>
    </div>
    {/* <!-- Service End --> */}

        <MainFooter/>

    </>
  )
}
