import React from 'react'
import TopHeader from './TopHeader'
import MainHeader from './MainHeader'
import MainFooter from './MainFooter'
import SeoData from './SeoData';

export default function CustomWebApplication() {
  return (
    <>
        <SeoData/>
        <TopHeader/>
      <div className="container-fluid position-relative p-0">
        <MainHeader/>
        <div className="container-fluid bg-primary py-5 bg-header" style={{marginBottom: "90px"}}>
            <div className="row py-5">
                <div className="col-12 pt-lg-5 mt-lg-5 text-center">
                    <h1 className="display-4 text-white animated zoomIn">Custom Web Application Development</h1>
                    <a href="#" className="h5 text-white">Services</a>
                    <i className="far fa-circle text-white px-2"></i>
                    <a href="#" className="h5 text-white">Custom Web Application Development</a>
                </div>
            </div>
        </div>
    </div>
    {/* <!-- Navbar & Carousel End --> */}


    {/* <!-- Full Screen Search Start --> */}
    <div className="modal fade" id="searchModal" tabIndex="-1">
        <div className="modal-dialog modal-fullscreen">
            <div className="modal-content" style={{background: "rgba(9, 30, 62, .7)"}}>
                <div className="modal-header border-0">
                    <button type="button" className="btn bg-white btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body d-flex align-items-center justify-content-center">
                    <div className="input-group" style={{maxWidth: "600px"}}>
                        <input type="text" className="form-control bg-transparent border-primary p-3" placeholder="Type search keyword"/>
                        <button className="btn btn-primary px-4"><i className="bi bi-search"></i></button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    {/* <!-- Full Screen Search End --> */}


    {/* <!-- About Start --> */}
    <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
        <div className="container py-5">
            <div className="row g-5">
                <div className="col-lg-7">
                    <div className="section-title position-relative pb-3 mb-5">
                        <h5 className="fw-bold text-primary text-uppercase">Custom Web Application Development</h5>
                        <h1 className="mb-0">Customized Softwares</h1>
                    </div>
                    <p className="mb-4" align="justify">At Ayodhya Technologies, we specialize in custom web application development that empowers businesses to streamline operations, enhance user engagement, and achieve their unique goals. Our experienced team of developers and designers collaborates closely with you to understand your specific requirements, ensuring that each solution is tailored to meet your needs.</p>

                    <p className="mb-4" align="justify">We leverage cutting-edge technologies and frameworks to create robust, scalable, and secure web applications that offer seamless user experiences across all devices. Whether you need a sophisticated enterprise application, a user-friendly portal, or a dynamic e-commerce platform, we have the expertise to bring your vision to life.</p>

                    <p className="mb-4" align="justify">Our development process is agile and iterative, allowing for flexibility and continuous improvement. From initial concept and wireframing to development, testing, and deployment, we ensure that every aspect of your application is meticulously crafted and rigorously tested for optimal performance.</p>

                    <p className="mb-4" align="justify">With a strong focus on user-centric design and functionality, we prioritize creating applications that are not only powerful but also intuitive and easy to use. Post-launch, we provide ongoing support and maintenance to ensure your application evolves with your business and stays ahead of industry trends.</p>

                    <p className="mb-4" align="justify">Partner with Ayodhya Technologies for custom web application development that drives efficiency, innovation, and growth. Contact us today to discuss how we can help transform your ideas into reality!</p>

                </div>
                <div className="col-lg-5" style={{minHeight: "500px"}}>
                    <div className="position-relative h-100">
                        <img className="position-absolute w-100 h-100 rounded wow zoomIn" data-wow-delay="0.9s" src="assets/img/custom_software.jpg" style={{objectFit: "cover"}}/>
                    </div>
                </div>
            </div>
        </div>
    </div>
    {/* <!-- Service End --> */}

        <MainFooter/>

    </>
  )
}
