import React from 'react'

export default function MainFooter() {
  return (
    <>
       <div className="container-fluid bg-dark text-light mt-5 wow fadeInUp" data-wow-delay="0.1s">
          <div className="container">
              <div className="row gx-5">
                  <div className="col-lg-4 col-md-6 footer-about">
                      <div className="d-flex flex-column align-items-center justify-content-center text-center h-100 bg-primary p-4">
                          <a href="index.html" className="navbar-brand">
                              <img src="assets/img/ayodhya_logo.png" style={{filter: "drop-shadow(1px 1px 0px black)"}}/>
                          </a>
                          <p className="mt-3 mb-4">Ayodhya Technologies is a dynamic software development company dedicated to providing innovative and scalable solutions to businesses worldwide.</p>
                          <form action="#">
                              <div className="input-group">
                                  <input type="number" className="form-control border-white p-3" placeholder="Your Mobile No." />
                                  <button className="btn btn-dark">Request a call</button>
                              </div>
                          </form>
                      </div>
                  </div>
                  <div className="col-lg-8 col-md-6">
                      <div className="row gx-5">
                          <div className="col-lg-4 col-md-12 pt-5 mb-5">
                              <div className="section-title section-title-sm position-relative pb-3 mb-4">
                                  <h3 className="text-light mb-0">Get In Touch</h3>
                              </div>
                              <div className="d-flex mb-2">
                                  <i className="bi bi-geo-alt text-primary me-2"></i>
                                  <p className="mb-0">City Center Mall, 2nd Floor-5, Takiya Para, Durg</p>
                              </div>
                              <div className="d-flex mb-2">
                                  <i className="bi bi-envelope-open text-primary me-2"></i>
                                  <p className="mb-0">info@ayodhyatechnologies.com</p>
                              </div>
                              <div className="d-flex mb-2">
                                  <i className="bi bi-telephone text-primary me-2"></i>
                                  <p className="mb-0">+97 7987274435</p>
                              </div>
                              <div className="d-flex mt-4">
                                  <a className="btn btn-primary btn-square me-2" href="#"><i className="fab fa-twitter fw-normal"></i></a>
                                  <a className="btn btn-primary btn-square me-2" href="#"><i className="fab fa-facebook-f fw-normal"></i></a>
                                  <a className="btn btn-primary btn-square me-2" href="#"><i className="fab fa-linkedin-in fw-normal"></i></a>
                                  <a className="btn btn-primary btn-square" href="#"><i className="fab fa-instagram fw-normal"></i></a>
                              </div>
                          </div>
                          <div className="col-lg-4 col-md-12 pt-0 pt-lg-5 mb-5">
                              <div className="section-title section-title-sm position-relative pb-3 mb-4">
                                  <h3 className="text-light mb-0">Quick Links</h3>
                              </div>
                              <div className="link-animated d-flex flex-column justify-content-start">
                                  <a className="text-light mb-2" href="#"><i className="bi bi-arrow-right text-primary me-2"></i>Home</a>
                                  <a className="text-light mb-2" href="#"><i className="bi bi-arrow-right text-primary me-2"></i>About Us</a>
                                  <a className="text-light mb-2" href="#"><i className="bi bi-arrow-right text-primary me-2"></i>Career</a>
                                  <a className="text-light mb-2" href="#"><i className="bi bi-arrow-right text-primary me-2"></i>News and Updates</a>
                                  <a className="text-light mb-2" href="#"><i className="bi bi-arrow-right text-primary me-2"></i>Latest Blog</a>
                                  <a className="text-light" href="#"><i className="bi bi-arrow-right text-primary me-2"></i>Contact Us</a>
                              </div>
                          </div>
                          <div className="col-lg-4 col-md-12 pt-0 pt-lg-5 mb-5">
                              <div className="section-title section-title-sm position-relative pb-3 mb-4">
                                  <h3 className="text-light mb-0">Popular Links</h3>
                              </div>
                              <div className="link-animated d-flex flex-column justify-content-start">
                                  <a className="text-light mb-2" href="#"><i className="bi bi-arrow-right text-primary me-2"></i>Website Development</a>
                                  <a className="text-light mb-2" href="#"><i className="bi bi-arrow-right text-primary me-2"></i>ERP Software</a>
                                  <a className="text-light mb-2" href="#"><i className="bi bi-arrow-right text-primary me-2"></i>E-Commerce Website</a>
                                  <a className="text-light mb-2" href="#"><i className="bi bi-arrow-right text-primary me-2"></i>Custom Software Development</a>
                                  <a className="text-light mb-2" href="#"><i className="bi bi-arrow-right text-primary me-2"></i>Mobile Appl Development</a>
                                  {/* <!-- <a className="text-light" href="#"><i className="bi bi-arrow-right text-primary me-2"></i>SEO</a> --> */}
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
      <div className="container-fluid text-white" style={{background: "#061429"}}>
          <div className="container text-center">
              <div className="row justify-content-end">
                  <div className="col-lg-8 col-md-6">
                      <div className="d-flex align-items-center justify-content-center" style={{height: "75px"}}>
                          <p className="mb-0">&copy; <a className="text-white border-bottom" href="https://ayodhyatechnologies.com">Ayodhya Technologies 2024</a>. All Rights Reserved. </p>
                      </div>
                  </div>
              </div>
          </div>
      </div>


      <a href="#" className="btn btn-lg btn-primary btn-lg-square rounded back-to-top"><i className="bi bi-arrow-up"></i></a>
    </>
  )
}
