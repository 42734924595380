import React from 'react'

// import '../assets/font-awesome/5.10.0/css/all.min.css';
// import '../assets/npm/bootstrap-icons1.4.1/font/bootstrap-icons.css';
// import '../assets/lib/owlcarousel/assets/owl.carousel.min.css';
// import '../assets/lib/animate/animate.min.css';
// import '../assets/css/bootstrap.min.css';
// import '../assets/css/style.css';
import SeoData from './SeoData';




import TopHeader from './TopHeader'
import MainHeader from './MainHeader'
import MainFooter from './MainFooter'

export default function HomePage() {
  return (
    <>
        {/* <SeoData/> */}
        <TopHeader/>
      <div className="container-fluid position-relative p-0">
        <MainHeader/>
       <div id="header-carousel" className="carousel slide carousel-fade" data-bs-ride="carousel">
            <div className="carousel-inner">
                <div className="carousel-item active">
                    <img className="w-100" src="assets/img/sc6.jpg" alt="Image"/>
                    <div className="carousel-caption d-flex flex-column align-items-center justify-content-center">
                        <div className="p-3" style={{maxWidth: "900px"}}>
                            <h5 className="text-white text-uppercase mb-3 animated slideInDown">Innovation on Imagination</h5>
                            <h1 className="display-1 text-white mb-md-4 animated zoomIn">Creative & Innovative Software Solution</h1>
                            <a href="pricing-plan" className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft">Free Quote</a>
                            <a href="contact-us" className="btn btn-outline-light py-md-3 px-md-5 animated slideInRight">Contact Us</a>
                        </div>
                    </div>
                </div>
                <div className="carousel-item">
                    <img className="w-100" src="assets/img/sc7.jpg" alt="Image"/>
                    <div className="carousel-caption d-flex flex-column align-items-center justify-content-center">
                        <div className="p-3" style={{maxWidth: "900px"}}>
                            <h5 className="text-white text-uppercase mb-3 animated slideInDown">Mobile Application Development</h5>
                            <h1 className="display-1 text-white mb-md-4 animated zoomIn">Device friendly mobile application solution for your business</h1>
                            <a href="pricing-plan" className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft">Free Quote</a>
                            <a href="contact-us" className="btn btn-outline-light py-md-3 px-md-5 animated slideInRight">Contact Us</a>
                        </div>
                    </div>
                </div>
            </div>
            <button className="carousel-control-prev" type="button" data-bs-target="#header-carousel"
                data-bs-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Previous</span>
            </button>
            <button className="carousel-control-next" type="button" data-bs-target="#header-carousel"
                data-bs-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Next</span>
            </button>
        </div>
    </div>
    {/* <!-- Navbar & Carousel End --> */}


    {/* <!-- Full Screen Search Start --> */}
    <div className="modal fade" id="searchModal" tabIndex="-1">
        <div className="modal-dialog modal-fullscreen">
            <div className="modal-content" style={{background: "rgba(9, 30, 62, .7)"}}>
                <div className="modal-header border-0">
                    <button type="button" className="btn bg-white btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body d-flex align-items-center justify-content-center">
                    <div className="input-group" style={{maxWidth: "600px"}}>
                        <input type="text" className="form-control bg-transparent border-primary p-3" placeholder="Type search keyword"/>
                        <button className="btn btn-primary px-4"><i className="bi bi-search"></i></button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    {/* <!-- Full Screen Search End --> */}


    {/* <!-- Facts Start --> */}
    <div className="container-fluid facts py-5 pt-lg-0">
        <div className="container py-5 pt-lg-0">
            <div className="row gx-0">
                <div className="col-lg-4 wow zoomIn" data-wow-delay="0.1s">
                    <div className="bg-primary shadow d-flex align-items-center justify-content-center p-4" style={{height: "150px"}}>
                        <div className="bg-white d-flex align-items-center justify-content-center rounded mb-2" style={{width: "60px", height: "60px"}}>
                            <i className="fa fa-users text-primary"></i>
                        </div>
                        <div className="ps-4">
                            <h4 className="text-white mb-0">Website Development</h4>
                            <h5 className="text-white mb-0" >Static and Dynamic</h5>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 wow zoomIn" data-wow-delay="0.3s">
                    <div className="bg-light shadow d-flex align-items-center justify-content-center p-4" style={{height: "150px"}}>
                        <div className="bg-primary d-flex align-items-center justify-content-center rounded mb-2" style={{width: "60px", height: "60px"}}>
                            <i className="fa fa-check text-white"></i>
                        </div>
                        <div className="ps-4">
                            <h4 className="text-primary mb-0">Mobile App Development</h4>
                            <h5 className="mb-0" >User friendly and attractive Design</h5>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 wow zoomIn" data-wow-delay="0.6s">
                    <div className="bg-primary shadow d-flex align-items-center justify-content-center p-4" style={{height: "150px"}}>
                        <div className="bg-white d-flex align-items-center justify-content-center rounded mb-2" style={{width: "60px", height: "60px"}}>
                            <i className="fa fa-award text-primary"></i>
                        </div>
                        <div className="ps-4">
                            <h4 className="text-white mb-0">Graphics Designing</h4>
                            <h5 className="text-white mb-0" >Image and Videos</h5>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    {/* <!-- Facts Start --> */}






    {/* <!-- About Start --> */}
    <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
        <div className="container py-5">
            <div className="row g-5">
                <div className="col-lg-7">
                    <div className="section-title position-relative pb-3 mb-5">
                        <h5 className="fw-bold text-primary text-uppercase">Ayodhya Technologies</h5>
                        <h1 className="mb-0">The Best IT Solution.</h1>
                    </div>
                    <p className="mb-4">Ayodhya Technologies is a dynamic software development company dedicated to providing innovative and scalable solutions to businesses worldwide. With a strong focus on cutting-edge technologies and a passion for excellence, we specialize in delivering tailored software solutions that transform ideas into reality.</p>
                    <div className="row g-0 mb-3">
                        <div className="col-sm-6 wow zoomIn" data-wow-delay="0.2s">
                            <h5 className="mb-3"><i className="fa fa-check text-primary me-3"></i>Quality Assurance</h5>
                            <h5 className="mb-3"><i className="fa fa-check text-primary me-3"></i>Professional Staff</h5>
                        </div>
                        <div className="col-sm-6 wow zoomIn" data-wow-delay="0.4s">
                            <h5 className="mb-3"><i className="fa fa-check text-primary me-3"></i>24/7 Support</h5>
                            <h5 className="mb-3"><i className="fa fa-check text-primary me-3"></i>Fair Prices</h5>
                        </div>
                    </div>
                    <div className="d-flex align-items-center mb-4 wow fadeIn" data-wow-delay="0.6s">
                        <div className="bg-primary d-flex align-items-center justify-content-center rounded" style={{width: "60px", height: "60px"}}>
                            <i className="fa fa-phone-alt text-white"></i>
                        </div>
                        <div className="ps-4">
                            <h5 className="mb-2">Call to ask any question</h5>
                            <h4 className="text-primary mb-0">+91 7987274435</h4>
                        </div>
                    </div>
                    <a href="pricing-plan" className="btn btn-primary py-3 px-5 mt-3 wow zoomIn" data-wow-delay="0.9s">Request A Quote</a>
                </div>
                <div className="col-lg-5" style={{minHeight: "500px"}}>
                    <div className="position-relative h-100">
                        <img className="position-absolute w-100 h-100 rounded wow zoomIn" data-wow-delay="0.9s" src="assets/img/home1.jpg" style={{objectFit: "cover"}}/>
                    </div>
                </div>
            </div>
        </div>
    </div>
    {/* <!-- About End --> */}


    {/* <!-- Features Start --> */}
    <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
        <div className="container py-5">
            <div className="section-title text-center position-relative pb-3 mb-5 mx-auto" style={{maxWidth: "600px"}}>
                <h5 className="fw-bold text-primary text-uppercase">Why Choose Us</h5>
                <h1 className="mb-0">We Are Here to Grow Your Business Exponentially</h1>
            </div>
            <div className="row g-5">
                <div className="col-lg-4">
                    <div className="row g-5">
                        <div className="col-12 wow zoomIn" data-wow-delay="0.2s">
                            <div className="bg-primary rounded d-flex align-items-center justify-content-center mb-3" style={{width: "60px", height: "60px"}}>
                                <i className="fa fa-cubes text-white"></i>
                            </div>
                            <h4>Best In Industry</h4>
                            <p className="mb-0">Ayodhya Technologies likely brings a wealth of expertise and experience in software development. </p>
                        </div>
                        <div className="col-12 wow zoomIn" data-wow-delay="0.6s">
                            <div className="bg-primary rounded d-flex align-items-center justify-content-center mb-3" style={{width: "60px", height: "60px"}}>
                                <i className="fa fa-award text-white"></i>
                            </div>
                            <h4>Quality Assurance</h4>
                            <p className="mb-0">Ayodhya Technologies ensures that software products are thoroughly tested and meet high standards of performance, security, and reliability.</p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4  wow zoomIn" data-wow-delay="0.9s" style={{minHeight: "350px"}}>
                    <div className="position-relative h-100">
                        <img className="position-absolute w-100 h-100 rounded wow zoomIn" data-wow-delay="0.1s" src="assets/img/why_choose.jpg" style={{objectFit: "cover"}}/>
                    </div>
                </div>
                <div className="col-lg-4">
                    <div className="row g-5">
                        <div className="col-12 wow zoomIn" data-wow-delay="0.4s">
                            <div className="bg-primary rounded d-flex align-items-center justify-content-center mb-3" style={{width: "60px", height: "60px"}}>
                                <i className="fa fa-users-cog text-white"></i>
                            </div>
                            <h4>Professional Staff</h4>
                            <p className="mb-0">Before talking destination, we shine a spotlight across your organization to fully understand it.</p>
                        </div>
                        <div className="col-12 wow zoomIn" data-wow-delay="0.8s">
                            <div className="bg-primary rounded d-flex align-items-center justify-content-center mb-3" style={{width: "60px", height: "60px"}}>
                                <i className="fa fa-phone-alt text-white"></i>
                            </div>
                            <h4>24/7 Support</h4>
                            <p className="mb-0">If you face any trouble, you can always let our dedicated support team help you. They are ready for you 24/7.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    {/* <!-- Features Start --> */}


    {/* <!-- Service Start --> */}
    <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
        <div className="container py-5">
            <div className="section-title text-center position-relative pb-3 mb-5 mx-auto" style={{maxWidth: "600px"}}>
                <h5 className="fw-bold text-primary text-uppercase">Our Services</h5>
                <h1 className="mb-0">Custom IT Solutions for Your Successful Business</h1>
            </div>
            <div className="row g-5">
                <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.3s">
                    <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                        <div className="service-icon">
                            <i className="fa fa-shield-alt text-white"></i>
                        </div>
                        <h4 className="mb-3">Website Design and development</h4>
                        <p className="m-0">We design and develop both the static and dynamic website for different sectors.</p>
                        <a className="btn btn-lg btn-primary rounded" href="#">
                            <i className="bi bi-arrow-right"></i>
                        </a>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.6s">
                    <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                        <div className="service-icon">
                            <i className="fa fa-chart-pie text-white"></i>
                        </div>
                        <h4 className="mb-3">Custom Web Application Development</h4>
                        <p className="m-0">We are experienced to develope the custom web application for the variety of businesses.</p>
                        <a className="btn btn-lg btn-primary rounded" href="#">
                            <i className="bi bi-arrow-right"></i>
                        </a>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.9s">
                    <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                        <div className="service-icon">
                            <i className="fa fa-code text-white"></i>
                        </div>
                        <h4 className="mb-3">E-commerce Web Application</h4>
                        <p className="m-0">Nowadays, more and more small businesses are incorporating e-commerce platforms as a part of their business systems.</p>
                        <a className="btn btn-lg btn-primary rounded" href="#">
                            <i className="bi bi-arrow-right"></i>
                        </a>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.3s">
                    <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                        <div className="service-icon">
                            <i className="fab fa-android text-white"></i>
                        </div>
                        <h4 className="mb-3">Apps Development</h4>
                        <p className="m-0">On both plateform Android and IOS we develop the mobile application for regarding business.</p>
                        <a className="btn btn-lg btn-primary rounded" href="#">
                            <i className="bi bi-arrow-right"></i>
                        </a>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.6s">
                    <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                        <div className="service-icon">
                            <i className="fa fa-search text-white"></i>
                        </div>
                        <h4 className="mb-3">SEO Optimization</h4>
                        <p className="m-0">in Ayodhya Technologies, we work for search Engine Optimization for website and business.</p>
                        <a className="btn btn-lg btn-primary rounded" href="#">
                            <i className="bi bi-arrow-right"></i>
                        </a>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.9s">
                    <div className="position-relative bg-primary rounded h-100 d-flex flex-column align-items-center justify-content-center text-center p-5">
                        <h3 className="text-white mb-3">Call Us For Quote</h3>
                        <p className="text-white mb-3">If you have any query regarding these services get connect to us on:</p>
                        <h2 className="text-white mb-0">+91 7987274435</h2>
                    </div>
                </div>
            </div>
        </div>
    </div>
    {/* <!-- Service End --> */}


    {/* <!-- Pricing Plan Start --> */}
    <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
        <div className="container py-5">
            <div className="section-title text-center position-relative pb-3 mb-5 mx-auto" style={{maxWidth: "600px"}}>
                <h5 className="fw-bold text-primary text-uppercase">Pricing Plans</h5>
                <h1 className="mb-0">We are Offering Competitive Prices for Our Clients</h1>
            </div>
            <div className="row g-0">
                <div className="col-lg-4 wow slideInUp" data-wow-delay="0.6s">
                    <div className="bg-light rounded">
                        <div className="border-bottom py-4 px-5 mb-4">
                            <h4 className="text-primary mb-1">Website Plan</h4>
                            <small className="text-uppercase">For Dynamic Websites</small>
                        </div>
                        <div className="p-5 pt-0">
                            <h1 className="display-5 mb-3">
                                <small className="align-top" style={{fontSize: "22px", lineHeight: "45px"}}>Starts from ₹</small>5000 Only
                                {/* <!-- <small className="align-bottom" style="font-size: 16px; line-height: 40px;">/ Year</small> --> */}
                            </h1>
                            <div className="d-flex justify-content-between mb-3"><span>Admin Panel</span><i className="fa fa-check text-primary pt-1"></i></div>
                            <div className="d-flex justify-content-between mb-3"><span>Minimum 6 Webpages</span><i className="fa fa-check text-primary pt-1"></i></div>
                            <div className="d-flex justify-content-between mb-3"><span>Saperate Blog posts</span><i className="fa fa-check text-primary pt-1"></i></div>
                            <div className="d-flex justify-content-between mb-2"><span>News and Updates</span><i className="fa fa-check text-primary pt-1"></i></div>
                            <div className="d-flex justify-content-between mb-2"><span>Gallery Album and Images</span><i className="fa fa-check text-primary pt-1"></i></div>
                            <div className="d-flex justify-content-between mb-2"><span>Career and more features</span><i className="fa fa-check text-primary pt-1"></i></div>
                            <a href="#" className="btn btn-primary py-2 px-4 mt-4">Order Now</a>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
                    <div className="bg-white rounded shadow position-relative" style={{zIndex:"1"}}>
                        <div className="border-bottom py-4 px-5 mb-4">
                            <h4 className="text-primary mb-1">Custom Web Application Plan</h4>
                            <small className="text-uppercase">For All Type of Business</small>
                        </div>
                        <div className="p-5 pt-0">
                            <h1 className="display-5 mb-3">
                                <small className="align-top" style={{fontSize: "22px", lineHeight: "45px"}}>Starts from ₹</small>15000 Only
                                {/* <!-- <small className="align-bottom" style="font-size: 16px; line-height: 40px;">/ Year</small> --> */}
                            </h1>
                            <div className="d-flex justify-content-between mb-3"><span>Admin Panel</span><i className="fa fa-check text-primary pt-1"></i></div>
                            <div className="d-flex justify-content-between mb-3"><span>Can add more users</span><i className="fa fa-check text-primary pt-1"></i></div>
                            <div className="d-flex justify-content-between mb-3"><span>Customized Report Generation</span><i className="fa fa-check text-primary pt-1"></i></div>
                            <div className="d-flex justify-content-between mb-3"><span>Live analytical Report</span><i className="fa fa-check text-primary pt-1"></i></div>
                            <div className="d-flex justify-content-between mb-2"><span>MIS Report Generation</span><i className="fa fa-check text-primary pt-1"></i></div>
                            <a href="#" className="btn btn-primary py-2 px-4 mt-4">Order Now</a>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 wow slideInUp" data-wow-delay="0.9s">
                    <div className="bg-light rounded">
                        <div className="border-bottom py-4 px-5 mb-4">
                            <h4 className="text-primary mb-1">Mobile Application with Web Application Plan</h4>
                            <small className="text-uppercase">For Any Type of Business</small>
                        </div>
                        <div className="p-5 pt-0">
                            <h1 className="display-5 mb-3">
                                <small className="align-top" style={{fontSize: "22px", lineHeight: "45px"}}>Starts from ₹</small>35000 Only
                                {/* <!-- <small className="align-bottom" style="font-size: 16px; line-height: 40px;">/ Month</small> --> */}
                            </h1>
                            <div className="d-flex justify-content-between mb-3"><span>Customized Attractive  Mobile App Design</span><i className="fa fa-check text-primary pt-1"></i></div>
                            <div className="d-flex justify-content-between mb-3"><span>Location Tracking,OTP Based Login</span><i className="fa fa-check text-primary pt-1"></i></div>
                            <div className="d-flex justify-content-between mb-3"><span>Real Time Report Generation</span><i className="fa fa-check text-primary pt-1"></i></div>
                            <div className="d-flex justify-content-between mb-3"><span>Multi Login Feature</span><i className="fa fa-check text-primary pt-1"></i></div>
                            <div className="d-flex justify-content-between mb-2"><span>Admin Panel Control</span><i className="fa fa-check text-primary pt-1"></i></div>
                            <a href="#" className="btn btn-primary py-2 px-4 mt-4">Order Now</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    {/* <!-- Pricing Plan End --> */}


    {/* <!-- Quote Start --> */}
    <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
        <div className="container py-5">
            <div className="row g-5">
                <div className="col-lg-7">
                    <div className="section-title position-relative pb-3 mb-5">
                        <h5 className="fw-bold text-primary text-uppercase">Request A Quote</h5>
                        <h1 className="mb-0">Need A Free Quote? Please Feel Free to Contact Us</h1>
                    </div>
                    <div className="row gx-3">
                        <div className="col-sm-6 wow zoomIn" data-wow-delay="0.2s">
                            <h5 className="mb-4"><i className="fa fa-reply text-primary me-3"></i>Reply within 24 hours</h5>
                        </div>
                        <div className="col-sm-6 wow zoomIn" data-wow-delay="0.4s">
                            <h5 className="mb-4"><i className="fa fa-phone-alt text-primary me-3"></i>24 hrs telephone support</h5>
                        </div>
                    </div>
                    <p className="mb-4">We' are committed to providing exceptional support for our software products. If you need assistance, our dedicated support team is here to help you. You can reach us through our website's support section, where you'll find a comprehensive knowledge base and FAQs to help troubleshoot common issues. For more specific inquiries, feel free to submit a support ticket or email us at info@ayodhyatechnologies.com</p>
                    <div className="d-flex align-items-center mt-2 wow zoomIn" data-wow-delay="0.6s">
                        <div className="bg-primary d-flex align-items-center justify-content-center rounded" style={{width: "60px", height: "60px"}}>
                            <i className="fa fa-phone-alt text-white"></i>
                        </div>
                        <div className="ps-4">
                            <h5 className="mb-2">Call to ask any question</h5>
                            <h4 className="text-primary mb-0">+91 7987274435</h4>
                        </div>
                    </div>
                </div>
                <div className="col-lg-5">
                    <div className="bg-primary rounded h-100 d-flex align-items-center p-5 wow zoomIn" data-wow-delay="0.9s">
                        <form>
                            <div className="row g-3">
                                <div className="col-xl-12">
                                    <input type="text" className="form-control bg-light border-0" placeholder="Your Name" style={{height: "55px"}}/>
                                </div>
                                <div className="col-12">
                                    <input type="email" className="form-control bg-light border-0" placeholder="Your Email" style={{height: "55px"}}/>
                                </div>
                                <div className="col-12">
                                    <select className="form-select bg-light border-0" style={{height: "55px"}}>
                                        <option defaultValue>Select A Service</option>
                                        <option value="1">Need Product details for purchase</option>
                                        <option value="2">Website Development Inquiry</option>
                                        <option value="3">Custom Software Development Inquiry</option>
                                        <option value="4">Mobile Application Development Inquiry</option>
                                        <option value="5">ERP Development Inquiry</option>
                                        <option value="6">E-Commerce Website Inquiry</option>
                                        <option value="7">Other Inquiry</option>
                                    </select>
                                </div>
                                <div className="col-12">
                                    <textarea className="form-control bg-light border-0" rows="3" placeholder="Message"></textarea>
                                </div>
                                <div className="col-12">
                                    <button className="btn btn-dark w-100 py-3" type="submit">Request A Quote</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
    {/* <!-- Quote End --> */}


    {/* <!-- Testimonial Start --> */}
    {/* <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
        <div className="container py-5">
            <div className="section-title text-center position-relative pb-3 mb-4 mx-auto" style={{maxWidth: "600px"}}>
                <h5 className="fw-bold text-primary text-uppercase">Testimonial</h5>
                <h1 className="mb-0">What Our Clients Say About Our Digital Services</h1>
            </div>
            <div className="owl-carousel testimonial-carousel wow fadeInUp" data-wow-delay="0.6s">
                <div className="testimonial-item bg-light my-4">
                    <div className="d-flex align-items-center border-bottom pt-5 pb-4 px-5">
                        <img className="img-fluid rounded" src="" alt="No Image Found" style={{width: "60px", height: "60px"}} />
                        <div className="ps-4">
                            <h4 className="text-primary mb-1">Karma CG Films</h4>
                            <small className="text-uppercase">CG Film Production</small>
                        </div>
                    </div>
                    <div className="pt-4 pb-5 px-5">
                        Very nice product and services team support is too good.
                    </div>
                </div>
            </div>
            <div className="owl-carousel testimonial-carousel wow fadeInUp" data-wow-delay="0.6s">
                <div className="testimonial-item bg-light my-4">
                    <div className="d-flex align-items-center border-bottom pt-5 pb-4 px-5">
                        <img className="img-fluid rounded" src="" alt="No Image Found" style={{width: "60px", height: "60px"}} />
                        <div className="ps-4">
                            <h4 className="text-primary mb-1">Karma CG Films</h4>
                            <small className="text-uppercase">CG Film Production</small>
                        </div>
                    </div>
                    <div className="pt-4 pb-5 px-5">
                        Very nice product and services team support is too good.
                    </div>
                </div>
            </div> 
            <div className="owl-carousel testimonial-carousel wow fadeInUp" data-wow-delay="0.6s">
                <div className="testimonial-item bg-light my-4">
                    <div className="d-flex align-items-center border-bottom pt-5 pb-4 px-5">
                        <img className="img-fluid rounded" src="" alt="No Image Found" style={{width: "60px", height: "60px"}} />
                        <div className="ps-4">
                            <h4 className="text-primary mb-1">Karma CG Films</h4>
                            <small className="text-uppercase">CG Film Production</small>
                        </div>
                    </div>
                    <div className="pt-4 pb-5 px-5">
                        Very nice product and services team support is too good.
                    </div>
                </div>
            </div>
        </div>
    </div> */}
    {/* <!-- Testimonial End --> */}


    {/* <!-- Team Start --> */}
    {/* <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
        <div className="container py-5">
            <div className="section-title text-center position-relative pb-3 mb-5 mx-auto" style={{maxWidth: "600px"}}>
                <h5 className="fw-bold text-primary text-uppercase">Team Members</h5>
                <h1 className="mb-0">Professional Stuffs Ready to Help Your Business</h1>
            </div>
            <div className="row g-5">
                <div className="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
                    <div className="team-item bg-light rounded overflow-hidden">
                        <div className="team-img position-relative overflow-hidden">
                            <img className="img-fluid w-100" src="assets/img/team-1.jpg" alt=""/>
                            <div className="team-social">
                                <a className="btn btn-lg btn-primary btn-lg-square rounded" href="#"><i className="fab fa-twitter fw-normal"></i></a>
                                <a className="btn btn-lg btn-primary btn-lg-square rounded" href="#"><i className="fab fa-facebook-f fw-normal"></i></a>
                                <a className="btn btn-lg btn-primary btn-lg-square rounded" href="#"><i className="fab fa-instagram fw-normal"></i></a>
                                <a className="btn btn-lg btn-primary btn-lg-square rounded" href="#"><i className="fab fa-linkedin-in fw-normal"></i></a>
                            </div>
                        </div>
                        <div className="text-center py-4">
                            <h4 className="text-primary">Devendra Katjhare</h4>
                            <p className="text-uppercase m-0">Director and Founder</p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 wow slideInUp" data-wow-delay="0.6s">
                    <div className="team-item bg-light rounded overflow-hidden">
                        <div className="team-img position-relative overflow-hidden">
                            <img className="img-fluid w-100" src="assets/img/team-2.jpg" alt=""/>
                            <div className="team-social">
                                <a className="btn btn-lg btn-primary btn-lg-square rounded" href="#"><i className="fab fa-twitter fw-normal"></i></a>
                                <a className="btn btn-lg btn-primary btn-lg-square rounded" href="#"><i className="fab fa-facebook-f fw-normal"></i></a>
                                <a className="btn btn-lg btn-primary btn-lg-square rounded" href="#"><i className="fab fa-instagram fw-normal"></i></a>
                                <a className="btn btn-lg btn-primary btn-lg-square rounded" href="#"><i className="fab fa-linkedin-in fw-normal"></i></a>
                            </div>
                        </div>
                        <div className="text-center py-4">
                            <h4 className="text-primary">Deepank Kobde</h4>
                            <p className="text-uppercase m-0">Senior Software Developer</p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 wow slideInUp" data-wow-delay="0.9s">
                    <div className="team-item bg-light rounded overflow-hidden">
                        <div className="team-img position-relative overflow-hidden">
                            <img className="img-fluid w-100" src="assets/img/team-3.jpg" alt=""/>
                            <div className="team-social">
                                <a className="btn btn-lg btn-primary btn-lg-square rounded" href="#"><i className="fab fa-twitter fw-normal"></i></a>
                                <a className="btn btn-lg btn-primary btn-lg-square rounded" href="#"><i className="fab fa-facebook-f fw-normal"></i></a>
                                <a className="btn btn-lg btn-primary btn-lg-square rounded" href="#"><i className="fab fa-instagram fw-normal"></i></a>
                                <a className="btn btn-lg btn-primary btn-lg-square rounded" href="#"><i className="fab fa-linkedin-in fw-normal"></i></a>
                            </div>
                        </div>
                        <div className="text-center py-4">
                            <h4 className="text-primary">Prashant Tamrakar</h4>
                            <p className="text-uppercase m-0">Digital Marketing Expert</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> */}
    {/* <!-- Team End --> */}


    {/* <!-- Blog Start --> */}
    {/* <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
        <div className="container py-5">
            <div className="section-title text-center position-relative pb-3 mb-5 mx-auto" style={{maxWidth: "600px"}}>
                <h5 className="fw-bold text-primary text-uppercase">Latest Blog</h5>
                <h1 className="mb-0">Read The Latest Articles from Our Blog Post</h1>
            </div>
            <div className="row g-5">
                <div className="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
                    <div className="blog-item bg-light rounded overflow-hidden">
                        <div className="blog-img position-relative overflow-hidden">
                            <img className="img-fluid" src="assets/img/blog-1.jpg" alt=""/>
                            <a className="position-absolute top-0 start-0 bg-primary text-white rounded-end mt-5 py-2 px-4" href="#">Web Design</a>
                        </div>
                        <div className="p-4">
                            <div className="d-flex mb-3">
                                <small className="me-3"><i className="far fa-user text-primary me-2"></i>John Doe</small>
                                <small><i className="far fa-calendar-alt text-primary me-2"></i>01 Jan, 2045</small>
                            </div>
                            <h4 className="mb-3">How to build a website</h4>
                            <p>Dolor et eos labore stet justo sed est sed sed sed dolor stet amet</p>
                            <a className="text-uppercase" href="#">Read More <i className="bi bi-arrow-right"></i></a>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 wow slideInUp" data-wow-delay="0.6s">
                    <div className="blog-item bg-light rounded overflow-hidden">
                        <div className="blog-img position-relative overflow-hidden">
                            <img className="img-fluid" src="assets/img/blog-2.jpg" alt=""/>
                            <a className="position-absolute top-0 start-0 bg-primary text-white rounded-end mt-5 py-2 px-4" href="#">Web Design</a>
                        </div>
                        <div className="p-4">
                            <div className="d-flex mb-3">
                                <small className="me-3"><i className="far fa-user text-primary me-2"></i>John Doe</small>
                                <small><i className="far fa-calendar-alt text-primary me-2"></i>01 Jan, 2045</small>
                            </div>
                            <h4 className="mb-3">How to build a website</h4>
                            <p>Dolor et eos labore stet justo sed est sed sed sed dolor stet amet</p>
                            <a className="text-uppercase" href="#">Read More <i className="bi bi-arrow-right"></i></a>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 wow slideInUp" data-wow-delay="0.9s">
                    <div className="blog-item bg-light rounded overflow-hidden">
                        <div className="blog-img position-relative overflow-hidden">
                            <img className="img-fluid" src="assets/img/blog-3.jpg" alt=""/>
                            <a className="position-absolute top-0 start-0 bg-primary text-white rounded-end mt-5 py-2 px-4" href="#">Web Design</a>
                        </div>
                        <div className="p-4">
                            <div className="d-flex mb-3">
                                <small className="me-3"><i className="far fa-user text-primary me-2"></i>John Doe</small>
                                <small><i className="far fa-calendar-alt text-primary me-2"></i>01 Jan, 2045</small>
                            </div>
                            <h4 className="mb-3">How to build a website</h4>
                            <p>Dolor et eos labore stet justo sed est sed sed sed dolor stet amet</p>
                            <a className="text-uppercase" href="#">Read More <i className="bi bi-arrow-right"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> */}

        <MainFooter/>

    </>
  )
}
