import React from 'react'
import TopHeader from './TopHeader'
import MainHeader from './MainHeader'
import MainFooter from './MainFooter'
import SeoData from './SeoData';

export default function MobileApplication() {
  return (
    <>
        <SeoData/>
        <TopHeader/>
      <div className="container-fluid position-relative p-0">
        <MainHeader/>
        <div className="container-fluid bg-primary py-5 bg-header" style={{marginBottom: "90px"}}>
            <div className="row py-5">
                <div className="col-12 pt-lg-5 mt-lg-5 text-center">
                    <h1 className="display-4 text-white animated zoomIn">Mobile App Development</h1>
                    <a href="#" className="h5 text-white">Services</a>
                    <i className="far fa-circle text-white px-2"></i>
                    <a href="#" className="h5 text-white">Mobile App Development</a>
                </div>
            </div>
        </div>
    </div>
    {/* <!-- Navbar & Carousel End --> */}


    {/* <!-- Full Screen Search Start --> */}
    <div className="modal fade" id="searchModal" tabIndex="-1">
        <div className="modal-dialog modal-fullscreen">
            <div className="modal-content" style={{background: "rgba(9, 30, 62, .7)"}}>
                <div className="modal-header border-0">
                    <button type="button" className="btn bg-white btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body d-flex align-items-center justify-content-center">
                    <div className="input-group" style={{maxWidth: "600px"}}>
                        <input type="text" className="form-control bg-transparent border-primary p-3" placeholder="Type search keyword"/>
                        <button className="btn btn-primary px-4"><i className="bi bi-search"></i></button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    {/* <!-- Full Screen Search End --> */}


    {/* <!-- About Start --> */}
    <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
        <div className="container py-5">
            <div className="row g-5">
                <div className="col-lg-7">
                    <div className="section-title position-relative pb-3 mb-5">
                        <h5 className="fw-bold text-primary text-uppercase">Mobile App Development</h5>
                        <h1 className="mb-0">Android / IOS</h1>
                    </div>
                    <p className="mb-4" align="justify">At Ayodhya Technologies, we specialize in mobile app development that transforms your ideas into engaging, user-friendly applications. Our expert team is dedicated to creating innovative mobile solutions tailored to your business needs, whether you're looking to build an iOS, Android, or cross-platform app.</p>

                    <p className="mb-4" align="justify">We understand that mobile applications are essential for connecting with customers and enhancing their experience. That's why we focus on user-centric design, ensuring that every app we develop is intuitive and visually appealing. Our development process incorporates the latest technologies and best practices, enabling us to deliver high-performance apps that are secure, scalable, and reliable.</p>

                    <p className="mb-4" align="justify">From concept to launch, we work closely with you to bring your vision to life. Our services include thorough market research, prototyping, development, and rigorous testing to ensure a smooth user experience. We also offer ongoing support and maintenance to keep your app updated and performing at its best in a constantly evolving digital landscape.</p>

                    <p className="mb-4" align="justify">At Ayodhya Technologies, we believe in building long-lasting partnerships with our clients. Your success is our priority, and we are committed to delivering exceptional mobile applications that drive engagement and growth. Let us help you elevate your brand with a custom mobile app that meets the demands of today’s mobile-first world. Contact us today to get started!</p>


                </div>
                <div className="col-lg-5" style={{minHeight: "500px"}}>
                    <div className="position-relative h-100">
                        <img className="position-absolute w-100 h-100 rounded wow zoomIn" data-wow-delay="0.9s" src="assets/img/mobile_app_development.jpg" style={{objectFit: "cover"}}/>
                    </div>
                </div>
            </div>
        </div>
    </div>
    {/* <!-- Service End --> */}

        <MainFooter/>

    </>
  )
}
