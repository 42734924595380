import React from 'react'
import TopHeader from './TopHeader'
import MainHeader from './MainHeader'
import MainFooter from './MainFooter'
import SeoData from './SeoData';

export default function PricingPlan() {
  return (
    <>
        <SeoData/>
        <TopHeader/>
      <div className="container-fluid position-relative p-0">
        <MainHeader/>
        <div className="container-fluid bg-primary py-5 bg-header" style={{marginBottom: "90px"}}>
            <div className="row py-5">
                <div className="col-12 pt-lg-5 mt-lg-5 text-center">
                    <h1 className="display-4 text-white animated zoomIn">Pricing Plans</h1>
                    <a href="#" className="h5 text-white">Home</a>
                    <i className="far fa-circle text-white px-2"></i>
                    <a href="#" className="h5 text-white">Pricing Plans</a>
                </div>
            </div>
        </div>
    </div>
    {/* <!-- Navbar & Carousel End --> */}


    {/* <!-- Full Screen Search Start --> */}
    <div className="modal fade" id="searchModal" tabIndex="-1">
        <div className="modal-dialog modal-fullscreen">
            <div className="modal-content" style={{background: "rgba(9, 30, 62, .7)"}}>
                <div className="modal-header border-0">
                    <button type="button" className="btn bg-white btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body d-flex align-items-center justify-content-center">
                    <div className="input-group" style={{maxWidth: "600px"}}>
                        <input type="text" className="form-control bg-transparent border-primary p-3" placeholder="Type search keyword"/>
                        <button className="btn btn-primary px-4"><i className="bi bi-search"></i></button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    {/* <!-- Full Screen Search End --> */}


    {/* <!-- About Start --> */}
    <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
        <div className="container py-5">
            <div className="section-title text-center position-relative pb-3 mb-5 mx-auto" style={{maxWidth: "600px"}}>
                <h5 className="fw-bold text-primary text-uppercase">Pricing Plans</h5>
                <h1 className="mb-0">We are Offering Competitive Prices for Our Clients</h1>
            </div>
            <div className="row g-0">
                <div className="col-lg-4 wow slideInUp" data-wow-delay="0.6s">
                    <div className="bg-light rounded">
                        <div className="border-bottom py-4 px-5 mb-4">
                            <h4 className="text-primary mb-1">Website Plan</h4>
                            <small className="text-uppercase">For Dynamic Websites</small>
                        </div>
                        <div className="p-5 pt-0">
                            <h1 className="display-5 mb-3">
                                <small className="align-top" style={{fontSize: "22px", lineHeight: "45px"}}>Starts from ₹</small>5000 Only
                                {/* <!-- <small className="align-bottom" style="font-size: 16px; line-height: 40px;">/ Year</small> --> */}
                            </h1>
                            <div className="d-flex justify-content-between mb-3"><span>Admin Panel</span><i className="fa fa-check text-primary pt-1"></i></div>
                            <div className="d-flex justify-content-between mb-3"><span>Minimum 6 Webpages</span><i className="fa fa-check text-primary pt-1"></i></div>
                            <div className="d-flex justify-content-between mb-3"><span>Saperate Blog posts</span><i className="fa fa-check text-primary pt-1"></i></div>
                            <div className="d-flex justify-content-between mb-2"><span>News and Updates</span><i className="fa fa-check text-primary pt-1"></i></div>
                            <div className="d-flex justify-content-between mb-2"><span>Gallery Album and Images</span><i className="fa fa-check text-primary pt-1"></i></div>
                            <div className="d-flex justify-content-between mb-2"><span>Career and more features</span><i className="fa fa-check text-primary pt-1"></i></div>
                            <a href="#" className="btn btn-primary py-2 px-4 mt-4">Order Now</a>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
                    <div className="bg-white rounded shadow position-relative" style={{zIndex:"1"}}>
                        <div className="border-bottom py-4 px-5 mb-4">
                            <h4 className="text-primary mb-1">Custom Web Application Plan</h4>
                            <small className="text-uppercase">For All Type of Business</small>
                        </div>
                        <div className="p-5 pt-0">
                            <h1 className="display-5 mb-3">
                                <small className="align-top" style={{fontSize: "22px", lineHeight: "45px"}}>Starts from ₹</small>15000 Only
                                {/* <!-- <small className="align-bottom" style="font-size: 16px; line-height: 40px;">/ Year</small> --> */}
                            </h1>
                            <div className="d-flex justify-content-between mb-3"><span>Admin Panel</span><i className="fa fa-check text-primary pt-1"></i></div>
                            <div className="d-flex justify-content-between mb-3"><span>Can add more users</span><i className="fa fa-check text-primary pt-1"></i></div>
                            <div className="d-flex justify-content-between mb-3"><span>Customized Report Generation</span><i className="fa fa-check text-primary pt-1"></i></div>
                            <div className="d-flex justify-content-between mb-3"><span>Live analytical Report</span><i className="fa fa-check text-primary pt-1"></i></div>
                            <div className="d-flex justify-content-between mb-2"><span>MIS Report Generation</span><i className="fa fa-check text-primary pt-1"></i></div>
                            <a href="#" className="btn btn-primary py-2 px-4 mt-4">Order Now</a>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 wow slideInUp" data-wow-delay="0.9s">
                    <div className="bg-light rounded">
                        <div className="border-bottom py-4 px-5 mb-4">
                            <h4 className="text-primary mb-1">Mobile Application with Web Application Plan</h4>
                            <small className="text-uppercase">For Any Type of Business</small>
                        </div>
                        <div className="p-5 pt-0">
                            <h1 className="display-5 mb-3">
                                <small className="align-top" style={{fontSize: "22px", lineHeight: "45px"}}>Starts from ₹</small>35000 Only
                                {/* <!-- <small className="align-bottom" style="font-size: 16px; line-height: 40px;">/ Month</small> --> */}
                            </h1>
                            <div className="d-flex justify-content-between mb-3"><span>Customized Attractive  Mobile App Design</span><i className="fa fa-check text-primary pt-1"></i></div>
                            <div className="d-flex justify-content-between mb-3"><span>Location Tracking,OTP Based Login</span><i className="fa fa-check text-primary pt-1"></i></div>
                            <div className="d-flex justify-content-between mb-3"><span>Real Time Report Generation</span><i className="fa fa-check text-primary pt-1"></i></div>
                            <div className="d-flex justify-content-between mb-3"><span>Multi Login Feature</span><i className="fa fa-check text-primary pt-1"></i></div>
                            <div className="d-flex justify-content-between mb-2"><span>Admin Panel Control</span><i className="fa fa-check text-primary pt-1"></i></div>
                            <a href="#" className="btn btn-primary py-2 px-4 mt-4">Order Now</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    {/* <!-- About End --> */}


        <MainFooter/>

    </>
  )
}
