import React from 'react'
import TopHeader from './TopHeader'
import MainHeader from './MainHeader'
import MainFooter from './MainFooter'
import SeoData from './SeoData';

export default function ContactUs() {
  return (
    <>
        <SeoData/>
        <TopHeader/>
      <div className="container-fluid position-relative p-0">
        <MainHeader/>
        <div className="container-fluid bg-primary py-5 bg-header" style={{marginBottom: "90px"}}>
            <div className="row py-5">
                <div className="col-12 pt-lg-5 mt-lg-5 text-center">
                    <h1 className="display-4 text-white animated zoomIn">Contact Us</h1>
                    <a href="#" className="h5 text-white">Home</a>
                    <i className="far fa-circle text-white px-2"></i>
                    <a href="#" className="h5 text-white">Contact Us</a>
                </div>
            </div>
        </div>
    </div>
    {/* <!-- Navbar & Carousel End --> */}


    {/* <!-- Full Screen Search Start --> */}
    <div className="modal fade" id="searchModal" tabIndex="-1">
        <div className="modal-dialog modal-fullscreen">
            <div className="modal-content" style={{background: "rgba(9, 30, 62, .7)"}}>
                <div className="modal-header border-0">
                    <button type="button" className="btn bg-white btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body d-flex align-items-center justify-content-center">
                    <div className="input-group" style={{maxWidth: "600px"}}>
                        <input type="text" className="form-control bg-transparent border-primary p-3" placeholder="Type search keyword"/>
                        <button className="btn btn-primary px-4"><i className="bi bi-search"></i></button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    {/* <!-- Full Screen Search End --> */}


    {/* <!-- About Start --> */}
    <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
        <div className="container py-5">
            <div className="section-title text-center position-relative pb-3 mb-5 mx-auto" style={{maxWidth: "600px"}}>
                <h5 className="fw-bold text-primary text-uppercase">Contact Us</h5>
                <h1 className="mb-0">If You Have Any Query, Feel Free To Contact Us</h1>
            </div>
            <div className="row g-5 mb-5">
                <div className="col-lg-4">
                    
                </div>
                <div className="col-lg-4">
                    
                </div>
                <div className="col-lg-4">
                    
                </div>
            </div>
            <div className="row g-5">
                <div className="col-lg-6 wow slideInUp" data-wow-delay="0.3s">
                    <form>
                        <div className="row g-3">
                            <div className="col-md-6">
                                <input type="text" className="form-control border-0 bg-light px-4" placeholder="Your Name" style={{height: "55px"}}/>
                            </div>
                            <div className="col-md-6">
                                <input type="email" className="form-control border-0 bg-light px-4" placeholder="Your Email" style={{height: "55px"}}/>
                            </div>
                            <div className="col-12">
                                <input type="text" className="form-control border-0 bg-light px-4" placeholder="Subject" style={{height: "55px"}}/>
                            </div>
                            <div className="col-12">
                                <textarea className="form-control border-0 bg-light px-4 py-3" rows="4" placeholder="Message"></textarea>
                            </div>
                            <div className="col-12">
                                <button className="btn btn-primary w-100 py-3" type="submit">Send Message</button>
                            </div>
                        </div>
                    </form>
                </div>
                <div className="col-lg-6 wow slideInUp" data-wow-delay="0.6s">
                    
                    <div className="d-flex align-items-center wow fadeIn" data-wow-delay="0.1s">
                        <div className="bg-primary d-flex align-items-center justify-content-center rounded" style={{width: "60px",height: "60px"}}>
                            <i className="fa fa-phone-alt text-white"></i>
                        </div>
                        <div className="ps-4">
                            <h6 className="mb-2">Call to ask any question</h6>
                            <h6 className="text-primary mb-0">+91 7987274435</h6>
                            <h6 className="text-primary mb-0">+91 7884058078</h6>
                        </div>
                    </div>
                    <br/>

                    <div className="d-flex align-items-center wow fadeIn" data-wow-delay="0.4s">
                        <div className="bg-primary d-flex align-items-center justify-content-center rounded" style={{width: "60px",height: "60px"}}>
                            <i className="fa fa-envelope-open text-white"></i>
                        </div>
                        <div className="ps-4">
                            <h6 className="mb-2">Email to get free quote</h6>
                            <h6 className="text-primary mb-0">info@ayodhyatechnologies.com</h6>
                        </div>
                    </div>
                    <br/>
                    <div className="d-flex align-items-center wow fadeIn" data-wow-delay="0.8s">
                        <div className="bg-primary d-flex align-items-center justify-content-center rounded" style={{width: "60px",height: "60px"}}>
                            <i className="fa fa-map-marker-alt text-white"></i>
                        </div>
                        <div className="ps-4">
                            <h6 className="mb-2">Visit our office</h6>
                            {/* <!-- <h6 className="text-primary mb-0"><b>Head Office:</b> Shitla Nagar near shantoshi mandir, Durg, Chhattisgarh, Bharat (491001)</h6><br/> --> */}
                            <h6 className="text-primary mb-0"><b>Branch Office:</b> City Center Mall(Second Floor 5) near farishta complex,<br/> Takiya Para, Durg, Chhattisgarh, Bharat (491001)</h6>
                        </div>
                    </div>


                </div>
            </div>

            <div className="row g-5">
                
                <div className="col-lg-12 wow slideInUp" data-wow-delay="0.6s"><br/></div>
                <div className="col-lg-12 wow slideInUp" data-wow-delay="0.6s">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d930.0098046112255!2d81.27966986960672!3d21.190600949204903!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a293dc1effb54fd%3A0x796ef72bf53f4761!2sAyodhya%20Technologies!5e0!3m2!1sen!2sin!4v1727951929965!5m2!1sen!2sin" width="100%" height="450" style={{border:"0"}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div>
            </div>



        </div>
    </div>

    {/* <!-- About End --> */}



        <MainFooter/>

    </>
  )
}
